import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link }  from 'gatsby'

import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import H4 from '../components/H4'
import Paragraph from '../components/Paragraph'
import H6 from '../components/H6'
import Div from '../components/Div/Div.styles'

import { Container } from '../components/styles/Layout.styles'

import { 
  styledCloudServer,
} from '../components/styles/Contrato.styles'
import { 
  styledTools,
  header,
} from '../components/styles/Tools.styles'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={7}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  // root: {
  //   flexGrow: 1,
  //   backgroundColor: theme.palette.background.paper,
  //   display: 'flex',
  //   justifyContent: 'center'
  // },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    fontSize: 50
  },
}));

export const PageTemplate = ({
  data,
  ...rest
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Fragment>
      <section css={styledTools}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H4>Termos de serviço</H4>
              <Paragraph>HostDime.com.br Termos de Serviço (Última Rev. 28/04/2021)</Paragraph>
              <Paragraph>Obrigado por fazer da HostDime Brasil, o seu parceiro de infraestrutura! Este Termo de Serviço Integrado (TSI) é o contrato entre a HostDime e o indivíduo ou entidade a quem foi atribuído um ID de cliente (doravante referido como cliente) e que responde legalmente referente aos serviços oferecidos em nosso site.</Paragraph>
              <Paragraph>Este TSI inclui nossa Política de Utilização Aceitável <Link to='politica-de-utilizacao-aceitavel-pua'>(PUA)</Link>, Política de Privacidade <Link to='politica-de-privacidade'>(PVD)</Link>, Garantia de Disponibilidade <Link to='politica-de-privacidade-garantia-de-disponibilidade-sla'>(SLA)</Link> e Política de Utilização de Recursos <Link to='politica-de-utilizacao-de-recursos-pur'>(PUR)</Link>. É muito importante que você leia este TSI por completo; Possuindo uma ID de cliente, você concorda em aceitar estes termos. A data de vigência deste TSI é a mesma em que lhe for atribuído uma ID de cliente (data de efetivação).</Paragraph>
              <Paragraph>Eventualmente, poderemos efetuar alterações neste TSI. Quando realizarmos alterações materiais neste presente TSI, faremos uma comunicação visível e adequada de acordo com as circunstâncias, como por exemplo, apresentando uma comunicação visível em seu painel de cliente CORE, ou através do envio de um e-mail ao Usuário. Poderemos enviar antecipadamente uma comunicação a você. Assim, é fundamental que você se certifique de ler qualquer comunicação atentamente.</Paragraph>
              <Paragraph>É obrigação do cliente garantir que os serviços sejam utilizados em conformidade com estas políticas. Encorajamos nossos clientes a incluir políticas não menos restritivas do que a nossa <Link to='politica-de-utilizacao-aceitavel-pua'>(PUA)</Link>, Política de Privacidade <Link to='politica-de-privacidade'>(PVD)</Link>, Política de Privacidade e <Link to='politica-de-utilizacao-de-recursos-pur'>(PUR)</Link> nos seus contratos com os seus clientes, ou aqueles que utilizam os serviços prestados por nós através de você (chamados "Usuários Finais").</Paragraph>
            </Column>
          </Row>
        </Container>
      </section>
      <section css={styledCloudServer}>
        <Container>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            mb={[60]}
          >
            <Column
              mb={[30]}
            >
              <Div
                maxWidth={['100%', '100%', 200]}
              >
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  className={classes.tabs}
                >
                  <Tab label="Geral" {...a11yProps(0)} />
                  <Tab label="Colocation" {...a11yProps(1)} />
                  <Tab label="Servidor Dedicado" {...a11yProps(2)} />
                  <Tab label="Cloud Server" {...a11yProps(3)} />
                  <Tab label="VPS" {...a11yProps(4)} />
                  <Tab label="Revenda e Hospedagem" {...a11yProps(5)} />
                  <Tab label="Registro de Domínio" {...a11yProps(6)} />
                </Tabs>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', '100%', 600]}
              >
                <TabPanel value={value} index={0}>
                  <H6>1. As seguintes disposições aplicam-se aos principais serviços ofertados pela HostDime Brasil</H6>
                  <Paragraph><span>1.1</span> As descrições dos recursos, características e limitações dos produtos e serviços que oferecemos são estabelecidos em nosso site. Estamos em acordo para fornecer os serviços ao usuário, da mesma forma que eles estão descritos em nosso site, a partir da data de vigência, de acordo com o termo. Caso essas características mudem após a data de vigência, não é obrigação da HostDime Brasil ajustar os seus serviços para aplicar essas alterações. Se você concordar em atualizar o serviço para as novas características com o valor ajustado podemos providenciar a alteração. Nos reservamos o direito de realizar alterações nos serviços em casos de: Indisponibilidade de componentes por parte do(s) fornecedor(es), custo proibitivo, se o período beta terminar, ou a qualquer momento no final do prazo de um serviço particular.</Paragraph>
                  <Paragraph><span>1.2</span> Existem 3 formas de contratar nossos serviços: (i) através de nosso site; (ii) abrindo um chamado para adquirir serviços adicionais, ou (iii) contato telefônico através de nossa central de atendimento. Quando você faz um pedido utilizando um destes meios você concorda em aceitar nossos termos.</Paragraph>
                  <Paragraph><span>1.3</span> Podemos recusar um pedido se determinarmos que este será usado para outras finalidades. Necessidades operacionais nos obrigam a alocar serviços entre os clientes por causa de uma limitação de recursos ou de causas alheias à nossa vontade. Podemos fazê-lo sem violar esta TSI.</Paragraph>
                  <Paragraph><span>1.4</span> O indivíduo ou entidade definida em nossos registros é o único autorizado para acessar os serviços. É de sua responsabilidade proteger senhas e outros métodos usados para acessar os serviços. Se você administra uma conta em nome de outra pessoa, você garante que administrará a conta com boa fé, e nos indenizará contra todas as perdas e obrigações incorridos sobre nós, além de administrar a conta de forma a evitar que seu cliente faça uma reivindicação contra nós.</Paragraph>
                  <Paragraph><span>1.5</span> Não nos responsabilizamos por quaisquer dados pessoais que não sejam os solicitados em nossos formulários no site, canais de atendimentos, área de cliente CORE, ou com qualquer departamento da empresa Hostdime, uma vez que a empresa apenas requer e trata os dados nos estritos termos firmados nos contratos relativos a cada tipo de serviço prestado e conforme a Política de Privacidade <Link to='politica-de-privacidade'>(PVD)</Link>, limitando o tratamento ao mínimo necessário para alcançar suas finalidades, de acordo com o estabelecido no art. 6º da Lei Geral de Proteção de Dados - LGPD.</Paragraph>
                  <Paragraph><span>1.6</span> Se você revender o serviço, ou incorporar o mesmo em seus próprios serviços, você é responsável por determinar que o serviço será adequado para seus clientes (usuários finais), assim como responsável pelo tratamento de dados de seus clientes e assegurar que eles não pratiquem qualquer atividade que faça com que você viole este TSI. Os usuários finais não são um terceiro beneficiário a este TSI, e você concorda em nos indenizar por quaisquer reclamações feitas contra nós por seus usuários finais de qualquer natureza. A menos que expressamente definido durante o pedido, não temos qualquer obrigação de fornecer suporte aos usuários finais. Se deixarmos de fornecer o serviço para você, por qualquer razão, você é o único responsável por garantir a continuidade do serviço para seus usuários finais.</Paragraph>
                  <Paragraph><span>1.7</span> Concordamos em usar esforços comercialmente razoáveis para fornecer ao cliente serviços de acordo e sujeito aos termos deste TSI e todos os acordos incorporados.</Paragraph>
                  <Paragraph><span>1.8</span> Você deve fornecer à HostDime, quando solicitado, para prestação de suporte e resolução de problemas relatados por você, informações, acessos e cooperação completas, incluindo, se necessário, dos seus clientes finais. Confiamos nas informações que você nos fornecer. É sua obrigação manter estas informações atualizadas. Não nos responsabilizamos caso haja falha de comunicação ou atraso de informações devido ao fato de suas informações de contato não estarem atualizadas. Você concorda que nós podemos fornecer a você informações que podem afetá-lo negativamente por e-mail. Certifique-se de que o endereço de e-mail usado no cadastro está configurado de acordo para evitar que nossos e-mails sejam rejeitados e/ou marcados como SPAM.</Paragraph>
                  <Paragraph><span>1.9</span> Todo software, hardware e alguns sistemas têm uma vida útil definida (End of Life). O cliente se compromete em usar o software, hardware e sistemas que são atualmente suportados pelos seus proprietários, incluindo aqueles que podem ter sido inicialmente fornecidos pela HostDime em conjunto com os serviços (por exemplo, um sistema operacional escolhido na contratação de um servidor). Quando esses itens estiverem próximos a encerrarem o seu tempo de vida útil (End of Life), é sua responsabilidade providenciar o upgrade para uma versão suportada. A HostDime não encoraja o uso de itens que estejam próximos de encerrar o seu tempo de vida útil.</Paragraph>
                  <Paragraph><span>1.10</span> Forneceremos, sem nenhum custo para você, um endereço IP primário por padrão, que será sujeito a alteração a qualquer momento. A HostDime deve manter e controlar a posse de todos os endereços de IP que podem ser atribuídos a você e nos reservamos o direito de alterar ou remover quaisquer destes endereços (para quaisquer serviços, desde que (i) seja fornecido à você pelo menos trinta dias de aviso prévio por escrito de qualquer alteração ou remoção; e (ii) você concorda em fornecer à HostDime toda a assistência solicitada para realizar qualquer alteração ou remoção.)</Paragraph>
                  <Paragraph><span>1.11</span> É sua responsabilidade realizar backups de seus arquivos. Se os serviços incluem serviços de backup, estes são fornecidos como um complemento para seus próprios esforços em manter cópias de seus arquivos. Como nenhum método de backup está livre de falhas, não damos garantias sobre o perfeito funcionamento de nossa solução de backup. Se os seus dados forem restaurados a partir de um backup mantido por nós, eles serão restaurados da mesma forma que encontra-se o backup. A Hostdime não se responsabiliza e não garante a integridade dos dados do backup a ser restaurado.</Paragraph>
                  <Paragraph><span>1.12</span> Se você adquirir qualquer produto de software da Microsoft por meio da HostDime, você concorda e é responsável por seguir os <a target='_blank' href='https://www.microsoft.com/pt-br/useterms'>Termos da Licença da Microsoft</a>.</Paragraph>
                  <H6>2. Termo</H6>
                  <Paragraph><span>2.1.</span> Este TSI começa a valer a partir da data de efetivação e continuará em vigor enquanto estivermos fornecendo um serviço para você.</Paragraph>
                  <Paragraph><span>2.2.</span> A data de efetivação de um serviço, é a data em que começará o processo de entrega do mesmo. Usamos programas que nos auxiliam na identificação de fraudes. Se durante o processo de entrega seu pedido for sinalizado como potencialmente fraudulento, o mesmo poderá não ser liberado. Até que o serviço esteja ativo você não deve considerar que seu pedido foi aprovado.</Paragraph>
                  <Paragraph><span>2.3.</span> Caso o pedido seja identificado como uma fraude em potencial, o pedido será cancelado e o cadastro rejeitado. Os dados do cadastro serão mantidos pelo tempo necessário para cumprimento de obrigações judiciais, como em casos de investigação, processos, denúncias, entre outros.</Paragraph>
                  <Paragraph><span>2.4.</span> xpirada a periodicidade da contratação do serviço, que poderá ser contratado com periodicidade mensal, trimestral, semestral ou anual, o mesmo será renovado automaticamente por um período de igual duração, a menos que uma das partes forneceu ao outro um aviso de rescisão, tal como estabelecido no parágrafo 4.</Paragraph>
                  <H6>3. Pagamentos</H6>
                  <Paragraph><span>3.1.</span> Os valores dos serviços constam no site ou através do envio de propostas por nossos consultores, junto com a descrição dos mesmos. Você será cobrado no momento em que fizer a contratação. Certos valores são baseados de acordo com a utilização dos Serviços. Estes serão calculados conforme propostas que descrevem os valores. Com exceção dos valores cobrados baseados na utilização do serviço, todos os serviços são pré-pagos, ou seja, você deve pagar antes de utilizar. Promoções com taxas especiais não se repetem e promoções oferecidas a outros clientes não necessariamente deve ser oferecida a você. Valores pagos à terceiros e taxas adicionais não são reembolsáveis.</Paragraph>
                  <Paragraph><span>3.2.</span> Os valores cobrados não incluem impostos. Todas as taxas impostas por uma entidade governamental serão adicionadas a menos que você forneça um documento de isenção válido. A HostDime não irá adicionar taxas que tenham como base sua renda de pessoa jurídica.</Paragraph>
                  <Paragraph><span>3.3.</span> A data de vencimento e valores constam nas páginas que descrevem detalhadamente cada serviço contratado.</Paragraph>
                  <Paragraph><span>3.4.</span> Se você optou por realizar pagamentos com cartão de crédito, nosso sistema lançará a cobrança com 5 (cinco) dias antes da data de vencimento. Você está ciente e autoriza esta cobrança em seu cartão de crédito. Não temos nenhuma responsabilidade em continuar fornecendo os serviços ou em preservar seus dados se o seu cartão de crédito for recusado por qualquer motivo.</Paragraph>
                  <Paragraph><span>3.6.</span> Se você abrir uma disputa de pagamento, você concorda em fornecer para nós uma notificação por escrito com provas que sustentam seus argumentos antes da data de vencimento. A HostDime analisará o seu pedido e responderá no prazo de 30 (trinta) dias do recebimento da notificação da disputa. Se você não concordar com a nossa resposta, ambos concordam em tentar a negociação amigável durante mais 30 (trinta) dias. Se após o período em questão o impasse não for resolvido, então ambas as partes concordam em levar o assunto conforme termos do parágrafo 7. Você concorda em usar este procedimento de boa fé e apenas para abrir disputas de pagamentos.</Paragraph>
                  <Paragraph><span>3.7.</span> Se você terminar um serviço de qualquer maneira que não seja conforme estabelecido neste TSI, ou não conseguir se adequar referente a uma violação material, então você concorda em pagar, no prazo de cinco dias de um aviso prévio, todas as taxas que teriam sido atribuídas a nós. Você concorda com a taxa de rescisão antecipada como forma de compensar a HostDime referente a oportunidades de negócio perdidas associados a recursos de rede limitada, e concorda que este é um incentivo material a HostDime.</Paragraph>
                  <Paragraph><span>3.8.</span> Se o serviço contratado é fornecido por terceiros, a HostDime não pode garantir que o valor cobrado por este fornecedor permanecerá o mesmo durante o período contratado. Caso o valor sofra alteração o aumento será repassado para você.</Paragraph>
                  <Paragraph><span>3.10.</span> HostDime Brasil não efetua reembolso dos pagamentos realizados.</Paragraph>
                  <Paragraph><span>3.11.</span> Todos os planos suspensos são cancelados e os respectivos dados armazenados no serviço contratado, são removidos após 30 dias de suspensão.</Paragraph>
                  <Paragraph><span>3.12.</span> A HostDime Brasil não efetua prorrogação de pagamento para faturas em aberto de qualquer produto comercializado.</Paragraph>
                  <Paragraph><span>3.13.</span> O atraso no pagamento de faturas em aberto de qualquer serviço contratado incidirá em juros de 0,29% ao dia e multa de 2% sobre o valor total da fatura em aberto.</Paragraph>
                  <Paragraph><span>3.14.</span> Ao efetuar o pagamento da fatura após a data de vencimento, os juros e multas serão adicionadas na fatura do mês subsequente.</Paragraph>
                  <Paragraph>Atualmente a HostDime trabalha com 4 diferentes formas de pagamento:</Paragraph>
                  <Paragraph>a. Cartão de Crédito e Paypal**</Paragraph>
                  <Paragraph>**Atenção: Pagamentos por Cartão de crédito e Paypal podem estar sujeitos a análise de comprovação de titularidade, onde nossa equipe entrará em contato através do endereço de e-mail cadastrado solicitando o preenchimento de um formulário e envio de documentos escaneados. Tais documentos serão utilizados para finalidade de comprovação de titularidade para aprovação do pedido e que serão armazenados no cadastro do cliente em nossos sistemas, com as devidas proteções de segurança.</Paragraph>
                  <Paragraph>Em casos de não confirmação dos dados, o valor pago será estornado através do mesmo meio utilizado para o pagamento e o serviço será cancelado. A HostDime não aceita pagamentos por cartão de crédito pré-pago. Nos casos em que for identificado pagamento por esse meio, a fatura será devidamente estornada. </Paragraph>
                  <Paragraph>b. Boleto Bancário</Paragraph>
                  <Paragraph>Pagamentos realizados por boleto bancário normalmente compensam em até 24h, exceto em finais de semana. Porém, esse prazo poderá ser de até 3 dias úteis - dependendo das rotinas dos agentes bancários.</Paragraph>
                  <Paragraph>Exclusivamente para pagamentos por boleto bancário, desenvolvemos um sistema de envio de comprovantes. A utilização deste sistema evitará que seu serviço fique indisponível caso esteja com seu pagamento em atraso ou com seu plano suspenso até que ocorra a confirmação do pagamento (de acordo com o prazo necessário para que a compensação por esta forma de pagamento ocorra). Tudo isso é realizado de forma automática, sem que haja a necessidade de entrar em contato com nossa equipe.</Paragraph>
                  <Paragraph>c. Bitcoin</Paragraph>
                  <Paragraph>Utilizamos o bitpay para processar os pagamentos por bitcoins. Ao optar por essa forma de pagamento você será direcionado para o link da bitpay, para que possa efetuar o pagamento. A compensação do pagamento é imediata, não há necessidade de envio de comprovantes para confirmação.</Paragraph>
                  <H6>4. Cancelamento e Suspensão</H6>
                  <Paragraph><span>4.1.</span> Qualquer uma das partes pode cancelar um determinado serviço. O seu pedido de cancelamento deve ser recebido por nós com 5 (cinco) dias antes do seu próximo ciclo de pagamento. Se você não fizer isso, o cancelamento só será efetivado mediante a confirmação do pagamento de faturas que estejam pendentes. Para que você possa solicitar o cancelamento do serviço você deve preencher o formulário de cancelamento disponível no painel Core. Além disso, qualquer das partes pode cancelar um serviço particular se houverem outras violações de acordo com os termos estabelecidos e o impasse não for resolvido no prazo de trinta dias após a apresentação da parte não infratora da notificação por escrito à parte infratora, ou imediatamente caso a violação seja incapaz de ser resolvida. No caso do nosso PUA será disponibilizado um período de tempo para tentar resolver qualquer violação. Qualquer uma das partes pode também terminar este TSI ou um serviço individual por motivos de falência ou insolvência.</Paragraph>
                  <Paragraph><span>4.2.</span> A HostDime pode suspender os serviços se você deixar de pagar as faturas até a data de vencimento, se o uso for proibido por lei ou regulamentação, se você fizer uso de software, hardware ou sistema descontinuado ou se você violar nossos termos que ambas as partes concordam causar danos materiais para a HostDime. Neste último caso, os débitos continuarão acumulando até que você se adeque aos termos.</Paragraph>
                  <Paragraph><span>4.3.</span> Se você solicitar cancelamento do serviço contratado de acordo com o parágrafo <span>4.1</span>, processaremos seu pedido em até 72 horas. Uma vez feita a solicitação de cancelamento, você tem 24 horas a partir de nossa notificação para reaver seu pedido e retirarmos seu serviço contratado da nossa fila de cancelamento. Caso contrário, seu serviço contratado será automaticamente cancelado e o mesmo ficará inacessível.</Paragraph>
                  <H6>5. Garantias, Limitações de Responsabilidade e Indenização</H6>
                  <Paragraph><span>5.1.</span> Você garante que possui ou tem o direito de uso de todos os dados, software e hardware que são usados para transmitir os dados a partir de nossos serviços.</Paragraph>
                  <Paragraph><span>5.2.</span> Nós garantimos que possuímos ou usamos licenças de terceiros para fornecer os serviços.</Paragraph>
                  <Paragraph><span>5.3.</span> As responsabilidades da HostDime junto com os seus contratantes e fornecedores limita-se a danos diretos com valor máximo estipulado pela quantidade de taxas pagas por você pelos três meses antecedentes ao evento que deu origem ao problema, exceto quando proibido por lei. Essa limitação de compromisso/responsabilidad, não se aplica a obrigação da HostDime em indenizá-lo (estabelecido no parágrafo 5.5) ou por violação de confidencialidade (estabelecido no parágrafo 6).</Paragraph>
                  <Paragraph><span>5.4.</span> APENAS GARANTIAS QUE ESTEJAM EXPRESSAMENTE PRESENTES NESTE TSI SERÃO COBERTAS PELA HOSTDIME. OS SERVIÇOS SÃO FORNECIDOS COMO ESTÃO E DE ACORDO COM A DISPONIBILIDADE. ALÉM DISSO, RENUNCIAMOS QUAISQUER REPRESENTAÇÕES IMPLÍCITAS, GARANTIAS OU CONDIÇÕES, INCLUINDO GARANTIAS DE COMERCIALIZAÇÃO, ADEQUAÇÃO A UM DETERMINADO PROPÓSITO, QUALIDADE SATISFATÓRIA, TÍTULO OU NÃO VIOLAÇÃO. CADA UMA DESTAS RENÚNCIAS SÃO APLICÁVEIS, A MENOS QUE PROIBIDAS POR LEI.</Paragraph>
                  <Paragraph>NA MEDIDA DO PERMITIDO POR LEI E INDEPENDENTEMENTE DA BASE PARA UMA REIVINDICAÇÃO, NENHUMA PARTE, SEUS ASSOCIADOS OU FORNECEDORES, SERÁ RESPONSÁVEL POR QUALQUER DANO INDIRETO (incluindo, mas não limitado a EMERGENTES, ESPECIAIS OU INCIDENTAIS, POR PERDA DE LUCROS, RECEITAS, INTERRUPÇÃO DE NEGÓCIOS OU PERDA DE INFORMAÇÕES), que tenham conexão COM ESTE TSI e/ou quaisquer contratos incorporados/adicionais. O presente parágrafo aplica-se MESMO QUE A PARTE TENHA SIDO INFORMADA SOBRE A POSSIBILIDADE de ocorrer estes danos OU QUE TAIS DANOS eram previsíveis. Essa limitação não se aplica a um violação da PUA, PUR OU OBRIGAÇÃO DE INDENIZAÇÃO de uma das partes.</Paragraph>
                  <Paragraph><span>5.5.</span> Ambas as partes deverão isentar o outro de responsabilidade, ou por opção própria resolver qualquer reclamação, processo ou processo instaurado ou ameaçado contra o outro, desde que seja baseado em: (i) uma violação de material desse termo de serviço, incluindo Política de Utilização Aceitável [PUA] e Política de Utilização de Recursos [PUR]; (ii) danos pessoais, morte ou danos físicos, perda ou roubo de propriedade pessoal tangível causada por falha/negligência ou mal comportamento intencional da outra parte.; (iii) uma reivindicação contra a HostDime nos Estados Unidos, Canadá, ou União Européia, que os serviços foram utilizados, ou de alguma forma facilitados, por um cliente de uma maneira que viola os direitos autorais, patentes, marca comercial, lei de segredo comercial ou uma violação das políticas da HostDime; ou (iv) no caso da HostDime, uma reclamação contra um cliente nos Estados Unidos de que os serviços de propriedade da HostDime violam direitos autorais, patentes, marca comercial e lei de segredo comercial. Este parágrafo será condicionado na notificação do indenizado ao indenizador prontamente durante a escrita da reclamação, dando ao indenizador total autoridade, informação e assistência para a defesa e resolução do mesmo; e que em caso de uma reivindicação por indenização expostas neste parágrafo, a reivindicação decorrer de uma modificação não autorizada dos servidores por você, combinação com outra propriedade intelectual (que não seja dono/proprietário) ou falha ao realizar atualização de software ou hardware, o indenizado deve ter o direito de participar na defesa de suas reivindicações (por conta própria). O indenizador não deve resolver uma reivindicação sem a aprovação do indenizado caso a resolução contenha uma admissão de responsabilidade ou falha/erro que poderia razoavelmente ser atribuído ao indenizado.</Paragraph>
                  <Paragraph><span>5.6.</span> Por força do que dispõe o art. 42, §1º, I, da LGPD, o controlador (CONTRATANTE) ou o operador (CONTRATADA), que, em razão do exercício de atividade de tratamento de dados pessoais, causar a outrem dano patrimonial, moral, individual ou coletivo, em violação à legislação de proteção de dados pessoais, é obrigado a repará-lo. A fim de assegurar a efetiva indenização ao titular dos dados, o operador responde solidariamente pelos danos causados pelo tratamento quando descumprir as obrigações da legislação de proteção de dados ou quando não tiver seguido as instruções lícitas do controlador, hipótese em que o operador equipara-se ao controlador.</Paragraph>
                  <H6>6. Confidencialidade</H6>
                  <Paragraph><span>6.1.</span> As informações confidenciais, restritas e rstritas com dados pessoais, doravante intituladas de "Informações Confidenciais" são informações não públicas tidas como privadas e que são conhecidas apenas entre as partes. Os seguintes itens estão dentro da definição de Informações Confidenciais sem necessidade de ser designado como tal: Contratos, aditivos, relatórios entre outras informações que sejam rotuladas como tal; como também qualquer informação colocada pelo cliente nos serviços como limitada pela Política de Privacidade (dados do cliente); dados pessoais de cadastro; configuração de rede HostDime, entre outros.</Paragraph>
                  <Paragraph><span>6.2.</span> Os seguintes itens estão fora da definição de informações confidenciais: informação que é ou é feita publicamente disponível, sem uma violação do presente parágrafo; era conhecido por uma das partes sem a obrigação legal de mantê-lo confidencial; sejam desenvolvidas independentemente por um partido sem referência à informação confidencial; é um comentário ou sugestão pública de clientes voluntários para melhorar os produtos ou serviços da HostDime.</Paragraph>
                  <Paragraph><span>6.3.</span>  Sujeitos ao presente parágrafo, as partes concordam em não compartilhar Informações Confidenciais a terceiros, exceto quando necessário para fornecer os serviços e, em seguida, apenas para os fins estabelecidos no presente TSI e na Política de Privacidade. As partes concordam em tomar medidas razoáveis para garantir a segurança e confidencialidade destas informações com o mesmo cuidado em proteger suas próprias informações, observando o limite de responsabilidade de cada uma das partes para com a segurança dessas informações. Uma das partes notificará a outra por escrito no prazo razoável após a sua descoberta de divulgação da Informação Confidencial - e se compromete em cooperar para recuperar o controle e evitar uma maior divulgação da Informação Confidencial.</Paragraph>
                  <Paragraph><span>6.4.</span> Qualquer uma das partes pode compartilhar informações confidenciais aos afiliados ou se for necessário para cumprir uma ordem judicial ou outra demanda do governo. No entanto, a parte sujeita à ordem judicial concorda em informar a outra parte dentro de um prazo razoável para permitir que o proprietário da Informação Confidencial proteste, a menos que o aviso seja proibido.</Paragraph>
                  <Paragraph><span>6.5.</span> As informações intituladas como "Restrita com DP (Dados Pessoais)" são informações que contém um alto nível de confidencialidade, que podem incluir dados pessoais como: nome, endereço, telefone, documentos, dados bancários, dados de saúde e outros dados que permitam a identificação pessoal de um usuário. </Paragraph>
                  <Paragraph><span>6.6.</span> Quando necessário divulgar dados pessoais (como no cumprimento da lei, por exemplo), tal divulgação deve ser devidamente registrada guardando a solicitação juntamente com a data, solicitante e qual DP foi divulgado. A solicitação de divulgação dos dados pessoais deverá ser informada ao cliente, salvo quando tal divulgação for judicialmente obrigatória e mantido em sigilo, preservando assim a confidencialidade da investigação.</Paragraph>
                  <Paragraph><span>6.7.</span> As obrigações desta seção permanecerão em vigor por 05(cinco) anos após o término deste acordo.</Paragraph>
                  <H6>7. Do Tratamento de Dados Pessoais no Serviço Contratado</H6>
                  <Paragraph><span>7.1 Para o serviço de Colocation:</span></Paragraph>
                  <Paragraph><span>7.1.1.</span> As Partes reconhecem que a CONTRATADA não realizará qualquer tipo de Tratamento de Dados Pessoais nos servidores da CONTRATANTE, uma vez que o contexto da prestação dos serviços trata-se do fornecimento de Colocation, ou seja, locação de espaço físico de Data Center para hospedagem dos equipamentos da CONTRATANTE. </Paragraph>
                  <Paragraph><span>7.1.2.</span> Cada uma das Partes concorda e garante que será individualmente responsável pelo cumprimento de suas obrigações decorrentes da LGPD e de eventuais regulamentações emitidas posteriormente pela ANPD.</Paragraph>
                  <Paragraph><span>7.1.3.</span> A CONTRATANTE concorda e garante que todos os dados pessoais armazenados no serviço contratado, são tratados de acordo com as leis de privacidade e proteção de dados aplicáveis, cumprindo com todos os princípios para Tratamento de Dados Pessoais estabelecidos pela LGPD.</Paragraph>
                  <Paragraph><span>7.1.4.</span> A CONTRATADA não coletará, usará, acessará, manterá, modificará, divulgará, transferirá ou, de outra forma, tratará dados pessoais nos servidores da CONTRATANTE. </Paragraph>
                  <Paragraph><span>7.1.5.</span> Em caso de contratação do serviço de Colocation nos Data Centers parceiros da HostDime,  a CONTRATANTE deverá garantir que tais terceiros se obriguem, por escrito, a garantir o mesmo nível de segurança estabelecido neste Contrato. A CONTRATADA será responsável por todas as ações e omissões realizadas por tais terceiros, relativas a segurança dos serviços, como se as tivesse realizado.</Paragraph>
                  <Paragraph><span>7.1.6</span> A CONTRATADA se compromete a instituir e manter um programa de gestão de segurança e privacidade da informação. Esse programa deverá estabelecer controles técnicos e administrativos apropriados para garantir a segurança e disponibilidade das informações, nos limites do serviço contratado (Colocation), além de garantir a conformidade com a Lei Geral de Proteção de Dados e demais normas que versem sobre privacidade e proteção de dados pessoais. Isso inclui a implementação de “Políticas Internas” que estabeleçam, dentre outras regras: (i) quais são as medidas de segurança aplicadas (técnicas e procedimentais) que garantam a segurança e disponibilidade das informações; (ii) como é realizada a gestão de incidentes, em caso de ocorrência envolvendo dados pessoais; (iii) qual o procedimento instituído que garante a constante atualização destas medidas; (iv) a limitação e controle de acesso ao serviço contratado; (v) a revisão periódica das medidas implementadas; (vi) condução de constantes treinamentos com os funcionários da companhia.</Paragraph>
                  <Paragraph><span>7.1.7</span> A CONTRATADA concorda e declara possuir medidas de segurança implementadas como políticas de controle de acesso, possuir uma política de segurança da informação instituída, a qual deverá determinar medidas técnicas e administrativas capazes de garantir a segurança e disponibilidade do serviço contratado. Tal política deverá instituir, mas não limitar a: </Paragraph>
                  <Paragraph>a) condução de constantes treinamentos com os funcionários da companhia; e </Paragraph>
                  <Paragraph>b) possuir medidas técnicas de controle, que deverá possuir, no mínimo:</Paragraph>
                  <Paragraph>
                    <ul>
                      <li>b.1) Controles de segurança física nas instalações da empresa;</li>
                      <li>b.2) Controle de acesso físico às instalações do Data Center e à área de Colocation;</li>
                    </ul>
                  </Paragraph>
                  <Paragraph><span>7.1.8.</span> Nas situações que a CONTRATANTE necessite auxílio da CONTRATADA, para atender requisições realizadas por titulares de dados, como o serviço contratado trata-se de um Colocation, a CONTRATADA poderá auxiliar a CONTRATANTE apenas em solicitações que envolvam os controles de segurança enquanto provedor de infraestrutura de Data Center, uma vez que os sistemas, aplicações, ferramentas em que os dados são armazenados, processados e tratados, são de responsabilidade da CONTRATANTE.</Paragraph>
                  <Paragraph><span>7.1.9.</span> A CONTRATADA, nos limites e responsabilidade do serviço contratado, cooperará com a CONTRATANTE em caso de qualquer Incidente, devendo: </Paragraph>
                  <Paragraph>
                    <ul>
                      <li><span>7.1.9.1.</span> Adotar todas medidas necessárias e razoáveis para remediar qualquer Incidente envolvendo os Dados Pessoais e minimizar possíveis efeitos negativos aos Titulares;</li>
                      <li><span>7.1.9.2.</span> Prover a CONTRATANTE com todas as informações necessárias à apuração do ocorrido;</li>
                      <li><span>7.1.9.3.</span> Abster-se de realizar qualquer comunicação a ANPD, autoridades públicas brasileiras, aos Titulares ou terceiros, sem a prévia e expressa concordância da CONTRATANTE, que deverá controlar a redação final dessas comunicações e quem deverá realizá-las, observadas as disposições da LGPD.</li>
                    </ul>
                  </Paragraph>
                  <Paragraph><span>7.1.10.</span> A CONTRATANTE poderá a qualquer momento acessar, modificar, eliminar, armazenar, transferir, compartilhar, coletar, entre outras formas de tratamento previstas na LGPD, dados pessoais em seus servidores alocados no espaço de Colocation contratado, observando os limites e responsabilidades previstas nos termos da Lei nº 13.709/18O.</Paragraph>
                  <Paragraph><span>7.1.11.</span> Caso a CONTRATADA seja destinatária de qualquer ordem judicial ou comunicação oficial que determine o fornecimento ou divulgação de informações pessoais, deverá notificar a CONTRATANTE, salvo quando a autoridade judicial requerer sigilo na investigação.</Paragraph>
                  <Paragraph><span>7.1.12.</span> Caso os serviços contratados pela CONTRATANTE estejam alocados no Data Center USA, da CONTRATADA, localizado em Orlando/FL - USA,  o CONTRATANTE está ciente de que os dados armazenados em seu serviço contratado são transferidos internacionalmente, e que a CONTRATADA implementará na infraestrutura de Data Center, as medidas apropriadas de segurança necessárias para a garantia da segurança e disponibilidade da infraestrutura do Data Center em que os dados pessoais armazenados no serviço de Colocation contratado estão alocados.</Paragraph>
                  <Paragraph><span>7.1.13.</span> Caso os serviços contratados pela CONTRATANTE estejam alocados no Data Center SPO, da CONTRATADA, localizado em Cotia/SP, o CONTRATANTE está ciente de que os dados armazenados em seu serviço contratado são mantidos através de um subcontratado, parceiro da HostDime Brasil, que implementa e mantém medidas apropriadas de segurança e disponibilidade da infraestrutura do Data Center em que os dados pessoais armazenados no serviço de Colocation contratado estão alocados.</Paragraph>
                  <Paragraph><span>7.2 Para os serviços de Servidor Dedicado, Cloud Server, VPS, Revenda e Hospedagem</span></Paragraph>
                  <Paragraph><span>7.2.1.</span> As Partes reconhecem que a CONTRATADA realizará o Tratamento de Dados Pessoais apenas no contexto da prestação dos serviços enquanto fornecedor de Infrastructure as a Service(IaaS), ou seja, Infraestrutura como serviço, caso a CONTRATANTE realize o tratamento de dados pessoais no serviço contratado. Nestas atividades de Tratamento, as Partes reconhecem e acordam que a CONTRATANTE é o Controlador dos Dados Pessoais, enquanto a CONTRATADA é o Operador dos Dados Pessoais, nos termos da lei Lei nº 13.709/18 e conforme detalhado ao longo do presente Contrato.</Paragraph>
                  <Paragraph><span>7.2.2.</span> A CONTRATADA tratará os Dados Pessoais exclusivamente em nome e sob as instruções lícitas da CONTRATANTE nos termos deste Contrato ou para cumprir com a legislação aplicável.</Paragraph>
                  <Paragraph><span>7.2.2.1.</span> A CONTRATANTE garante que o Tratamento dos Dados Pessoais pela CONTRATADA de acordo com as instruções da CONTRATANTE, não fará com que a CONTRATADA viole qualquer lei ou regulamento, incluindo, sem limitação, a LGPD.</Paragraph>
                  <Paragraph><span>7.2.2.2.</span> A CONTRATADA irá cessar o Tratamento dos Dados Pessoais e notificará imediatamente a CONTRATANTE por escrito, a menos que seja proibido de fazê-lo, se tomar conhecimento ou acreditar que qualquer instrução ou Dado Pessoal tratado viola a LGPD ou qualquer outra lei ou regulamento aplicável. </Paragraph>
                  <Paragraph><span>7.2.3.</span> Cada uma das Partes concorda e garante que será individualmente responsável pelo cumprimento de suas obrigações decorrentes da LGPD e de eventuais regulamentações emitidas posteriormente pela ANPD.</Paragraph>
                  <Paragraph><span>7.2.4.</span> A CONTRATANTE concorda e garante que todos os dados pessoais armazenados no serviço contratado, são tratados de acordo com as leis de privacidade e proteção de dados aplicáveis, cumprindo com todos os princípios para Tratamento de Dados Pessoais estabelecidos pela LGPD.</Paragraph>
                  <Paragraph><span>7.2.5.</span> A CONTRATADA concorda e garante que realizará o Tratamento dos Dados Pessoais nos limites e para as finalidades permitidas por este Contrato, ou outras definidas pela CONTRATANTE, por meio de aditivos a este contrato. Igualmente, A CONTRATADA não coletará, usará, acessará, manterá, modificará, divulgará, transferirá ou, de outra forma, tratará dados pessoais, sem a ciência e autorização da CONTRATANTE. </Paragraph>
                  <Paragraph><span>7.2.6.</span> A CONTRATADA assegurará que os Dados Pessoais não sejam acessados, compartilhados ou transferidos para terceiros que não sejam os Data Centers próprios ou parceiros da Hostdime(CONTRATADA), sem o consentimento prévio por escrito da CONTRATANTE. Caso a CONTRATANTE autorize estas operações de tratamento, a CONTRATADA deverá garantir que tais terceiros se obriguem, por escrito, a garantir a mesma proteção aos Dados Pessoais estabelecida neste Contrato. A CONTRATADA será responsável por todas as ações e omissões realizadas por tais terceiros, relativas ao Tratamento dos Dados Pessoais, como se as tivesse realizado.</Paragraph>
                  <Paragraph><span>7.2.7.</span> A CONTRATADA se compromete a instituir e manter um programa de gestão de privacidade. Esse programa deverá estabelecer controles técnicos e administrativos apropriados para garantir a confidencialidade e disponibilidade das informações, nos limites do serviço contratado (IaaS), além de garantir a conformidade com a Lei Geral de Proteção de Dados e demais normas que versem sobre privacidade e proteção de dados pessoais. Isso inclui a implementação de “Políticas Internas” que estabeleçam, dentre outras regras: (i) quais são as medidas de segurança aplicadas (técnicas e procedimentais) que garantam a confidencialidade e disponibilidade das informações; (ii) como é realizada a gestão de incidentes, em caso de ocorrência envolvendo dados pessoais; (iii) qual o procedimento instituído que garante a constante atualização destas medidas; (iv) a limitação e controle de acesso ao serviço contratado; (v) a revisão periódica das medidas implementadas; (vi) condução de constantes treinamentos com os funcionários da companhia.</Paragraph>
                  <Paragraph><span>7.2.8.</span> A CONTRATADA concorda e declara possuir medidas implementadas para proteger as informações pessoais que possam ser tratadas no serviço contratado, possuir uma política de segurança da informação instituída, a qual deverá determinar medidas técnicas e administrativas capazes de garantir a, disponibilidade e confidencialidade das informações tratadas no serviço contratado. Tal política deverá instituir, mas não limitar a: </Paragraph>
                  <Paragraph>a) condução de constantes treinamentos com os funcionários da companhia; e </Paragraph>
                  <Paragraph>b) possuir medidas técnicas de controle, que deverá possuir, no mínimo:</Paragraph>
                  <Paragraph>
                    <ul>
                      <li>b.1) Controle de acesso físico às instalações do Data Center em que os dados estão alocados nos serviços contratados;</li>
                      <li>b.2) Controles de proteção da rede da infraestrutura do Data Center;</li>
                      <li>b.3) Controle de sanitização de mídias físicas de armazenamento e decorrentes de cancelamento dos serviços, upgrades, downgrades ou falha do equipamento. </li>
                    </ul>
                  </Paragraph>
                  <Paragraph><span>7.2.9.</span> Nas situações que a CONTRATANTE necessite auxílio da CONTRATADA, para atender requisições realizadas por titulares de dados, como o serviço contratado trata-se de um IaaS(Infraestrutura como Serviço), a CONTRATADA poderá auxiliar a CONTRATANTE apenas em solicitações que envolvam os controles de segurança e privacidade enquanto provedor de infraestrutura de Data Center, uma vez que os sistemas, aplicações, ferramentas em que os dados são armazenados, processados e tratados, são de responsabilidade da CONTRATANTE.</Paragraph>
                  <Paragraph><span>7.2.10.</span> A CONTRATADA cooperará com a CONTRATANTE em caso de qualquer Incidente, devendo: </Paragraph>
                  <Paragraph><span>7.2.10.1.</span> Adotar todas medidas necessárias e razoáveis para remediar qualquer Incidente envolvendo os Dados Pessoais e minimizar possíveis efeitos negativos aos Titulares;</Paragraph>
                  <Paragraph><span>7.2.10.2.</span> Prover a CONTRATANTE com todas as informações necessárias à apuração do ocorrido;</Paragraph>
                  <Paragraph><span>7.2.10.3.</span> Abster-se de realizar qualquer comunicação a ANPD, autoridades públicas brasileiras, aos Titulares ou terceiros, sem a prévia e expressa concordância da CONTRATANTE, que deverá controlar a redação final dessas comunicações e quem deverá realizá-las, observadas as disposições da LGPD.</Paragraph>
                  <Paragraph><span>7.2.11.</span> A CONTRATANTE poderá a qualquer momento acessar, modificar, eliminar, armazenar, transferir, compartilhar, coletar, entre outras formas de tratamento previstas na LGPD, dados pessoais no serviço contratado, observando os limites e responsabilidades previstas nos termos da Lei nº 13.709/18O. A CONTRATADA poderá, mediante solicitação por escrito da CONTRATANTE, ou quando da extinção do vínculo contratual e obrigacional existente, eliminar os dados pessoais armazenados no serviço contratado e realizar a exclusão definitiva e permanente dos mesmos.</Paragraph>
                  <Paragraph><span>7.2.12.</span> Caso a CONTRATADA seja destinatária de qualquer ordem judicial ou comunicação oficial que determine o fornecimento ou divulgação de informações pessoais, deverá notificar a CONTRATANTE, salvo quando a autoridade judicial requerer sigilo na investigação.</Paragraph>
                  <Paragraph><span>7.2.12.</span> Caso os serviços contratados pela CONTRATANTE estejam alocados no Data Center USA, da CONTRATADA, localizado em Orlando/FL - USA,  o CONTRATANTE está ciente de que os dados armazenados em seu serviço contratado são transferidos internacionalmente, e que a CONTRATADA implementará na infraestrutura de Data Center, as medidas apropriadas de segurança e disponibilidade da infraestrutura do Data Center em que os dados pessoais armazenados no serviço contratado estão alocados.</Paragraph>
                  <Paragraph><span>7.2.13.</span> Caso os serviços contratados pela CONTRATANTE estejam alocados no Data Center SPO, da CONTRATADA, localizado em Cotia/SP, o CONTRATANTE está ciente de que os dados armazenados em seu serviço contratado são mantidos através de um subcontratado, parceiro da HostDime Brasil, que implementa e mantém medidas apropriadas de segurança e disponibilidade da infraestrutura do Data Center em que os dados pessoais armazenados no serviço contratado estão alocados.</Paragraph>
                  <H6>8. Monitoramento e Segurança</H6>
                  <Paragraph><span>8.1.</span> A HostDime leva a segurança de sua rede muito a sério. Para garantir a segurança, integridade e privacidade dos dados,  usamos ferramentas que monitoram o tráfego e o acesso à rede.  Tudo isso com o objetivo de detectar possíveis atividades ilícitas por meio da rede, que podem prejudicar os serviços e os nossos clientes. Estes programas coletam informações a respeito do uso do usuário referente aos serviços, site da HostDime e rede (Informações de Tráfego).</Paragraph> 
                  <Paragraph>Em caso de detecção de possível atividades ilícitas por meio de nossa rede, as seguintes ações serão tomadas:</Paragraph>
                  <Paragraph>
                    <ul>
                      <li><span>Notificação via chamado:</span> Um chamado será aberto por nossa equipe, relatando o incidente de segurança. O chamado estará disponível em seu painel de cliente Core.</li>
                      <li><span>Ação após a notificação:</span> Caso não haja retorno do contratante dentro de <span>05 dias corridos</span> após a abertura da notificação, o endereço IP envolvido no incidente será adicionado em <span>rota nula</span>, até obtermos retorno via chamado. </li>
                      <li><span>Ações em caso de reincidência:</span> Em caso de reincidência do incidente de segurança envolvendo o mesmo endereço IP e serviço, as seguintes ações serão tomadas de acordo com o nível de gerenciamento do serviço:</li>
                      <Paragraph>
                        <ul>
                          <li><span>Gerenciamento Básico: </span></li>
                          <ul>
                            <li>Multa no valor de R$ 500,00; </li>
                            <li>Suspensão do serviço em caso de não tratativa; </li>
                            <li>Cancelamento do serviço em caso de nova ocorrência de incidente da mesma natureza.</li>
                          </ul>
                          <li><span>Gerenciamento Avançado:</span></li>
                          <ul>
                            <li>Multa no valor de R$ 500,00;</li>
                            <li>Suspensão do serviço em caso de não tratativa;</li>
                            <li>Cancelamento do <span>Gerenciamento Avançado</span> em caso de nova ocorrência de incidente da mesma natureza;</li>
                            <li>Cancelamento do serviço em caso de nova ocorrência de incidente da mesma natureza.</li>
                          </ul>
                        </ul>
                      </Paragraph>
                    </ul>
                  </Paragraph>
                  <H6>9. Disposições Gerais</H6>
                  <Paragraph><span>9.1.</span> Se a HostDime ou você for incapaz de cumprir as respectivas obrigações devido a circunstâncias externas ou evento de força maior, o cumprimento das obrigações poderá ser perdoado pelo período de tempo em que essas circunstâncias extraordinárias persistirem. As seguintes circunstâncias se encontram fora do escopo de um Evento de Força Maior: Falta ou indisponibilidade de fundos. Se a HostDime for incapaz de fornecer os Serviços por um período de 30 (trinta) dias ou mais, não obstante as tentativas para remediar os Eventos de Força Maior, você tem o direito de finalizar os serviços afetados sem a necessidade de pagar faturas pendentes.</Paragraph>
                  <Paragraph><span>9.2.</span> De tempos em tempos poderemos receber mandados e intimações de entidades para a obtenção de informações que você nos fornecerá (e pedido de aplicação da lei). A menos que o pedido de execução da lei proíba ou haja pedido para que não seja discutido, nós lhe informaremos a respeito da necessidade em divulgar as suas informações.</Paragraph>
                  <Paragraph><span>9.3.</span> Se você está envolvido em um processo civil, nós não temos obrigações de fornecer nenhuma assistência para que você cumpra suas obrigações perante a lei, como intimações. Se formos obrigados a cumprir uma intimação para os Serviços que fornecemos a você, iremos cobrar R$300,00 por hora, mais despesas razoáveis, para cumprir.</Paragraph>
                  <Paragraph><span>9.4.</span> Este termo de serviço é o final, completo e expressivo referente ao nosso acordo. O mesmo substitui todas as comunicações orais e escritas anteriormente entre as partes envolvidas na discussão do assunto em questão.</Paragraph>
                  <Paragraph><span>9.5.</span> Este termo de serviço e todos os contratos incorporados por referência devem ser governados/regidos pelas leis da República Federativa do Brasil sem dizer respeito a sua escolha de leis ou conflitos de princípios legais. Todas as disputas entre você e a HostDime, de qualquer natureza, serão submetidas nos tribunais do estado localizado na cidade de João Pessoa, Paraíba, Brasil. Nenhuma das partes envolvidas deve contestar a notificação feita pelo tribunal. AS PARTES RENUNCIAM JULGAMENTO POR JURI EM QUALQUER DISPUTA. As Convenções das Nações Unidas/ONU sobre contratos para a Venda Internacional de Bens/Mercadorias não se aplicam. O cliente reconhece e concorda que o preço é baseado em grande medida entre as partes, direitos respectivos, obrigações e limitações estabelecidas neste acordo</Paragraph>
                  <Paragraph><span>9.6.</span> Este TSI e todos os contratos incorporados não podem ser alterados, exceto por um consentimento por escrito de um representante autorizado da HostDime e do cliente.</Paragraph>
                  <Paragraph><span>9.7.</span> Nenhuma falta ou atraso em exercer qualquer direito, recurso ou poder será uma renúncia dele.</Paragraph>
                  <Paragraph><span>9.8.</span> Este Termo de Serviço e todos os contratos incorporados por referência serão obrigatórios para as partes e seus sucessores e cessionários. Qualquer uma das partes pode transferir este termo de serviço e todos os contratos incorporados, exceto nas seguintes circunstâncias: para um concorrente ou para uma entidade que não possui serviços ativos e/ou operações.</Paragraph>
                  <Paragraph><span>9.9.</span> Se qualquer cláusula neste termo de serviço ou contrato incorporado se encontra ilegal ou não seja aplicável, tal cláusula deverá ser atualizada de certa forma para se tornar legal, porém o termo e todos os contratos incorporados permanecerão em efeito. Sob nenhuma circunstância tal constatação deve fazer com que o contrato seja anulado.</Paragraph>
                  <Paragraph><span>9.10.</span> Todos os anúncios devem ser feitos por escrito e entrarão em vigor a partir: (i) da data de recebimento real ou (ii) cinco dias úteis após o primeiro registro de correspondências. Notificações à HostDime deverão ser enviadas para o endereço listado na seção sobre ou página de contato no site da HostDime. Notificações direcionadas à você serão enviadas para o endereço fornecido na realização do cadastro conosco.</Paragraph>
                  <Paragraph><span>9.11.</span> Se existir algum conflito entre os contratos e entre as partes, eles devem ter a seguinte ordem decrescente de procedências: Política de uso aceitável (PUA), Política de Privacidade, acordo de nível de serviço e só depois termo de serviço.</Paragraph>
                  <Paragraph><span>9.12. Nenhuma pessoa ou entidade além de você, nossos respectivos sucessores e cessionários, terá direito a propor ação para fazer qualquer disposição deste (TSI) ou contratos incorporados contra qualquer um de nós. Para efeitos do presente acordo, podem ser ambas consideradas contratantes independentes e não podem fazer representações, reclamações ou garantias em nome da outra. Cada uma das partes envolvidas concorda em indenizar a outra nos termos do parágrafo 5.5, desde que a reivindicação esteja dentro do escopo deste parágrafo.</span></Paragraph>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <H6>1. As seguintes disposições aplicam-se ao serviço de Colocation</H6>
                  <Paragraph><span>1.1</span> Quando você contrata o serviço de colocation conosco, é concedida uma licença (mas não de quaisquer outros direitos de propriedade) para ocupar ou utilizar determinado espaço dentro de um data center HostDime, como descrito mais particularmente durante o processo de contratação. Você pode usar o espaço para alocar hardware, software e outros equipamentos, licenciados ou alugados por você ou seus representantes e utilizados para receber os serviços (o equipamento é fornecido pelo contratante).</Paragraph>
                  <Paragraph><span>1.2</span> A HostDime deve realizar manutenções periódicas para manter as instalações e o espaço em bom estado para receber os equipamentos fornecidos por você, nosso cliente. O espaço deve ser entregue e aceito "como está". A infraestrutura será monitorada vinte e quatro horas por dia, sete dias por semana, com acesso por cartão chave de segurança. A infraestrutura dispõe ainda de energia ininterrupta que utiliza sistemas UPS com gerador de energia de grande capacidade como backup.</Paragraph>
                  <Paragraph><span>1.3</span> Você pode ocupar o espaço com seus próprios equipamentos. Você não pode subalugar, alugar, vender ou de nenhuma outra forma ceder ou transferir o direito de ocupação ou outros direitos sobre o espaço a terceiros sem o nosso prévio consentimento por escrito.</Paragraph>
                  <Paragraph><span>1.4</span> Antes de acessar o espaço para qualquer finalidade, você deve fornecer à HostDime (e manter atualizada) uma lista de seus funcionários e pessoas autorizadas que terão acesso ao espaço contratado em seu nome (Contatos autorizados). Os contatos autorizados podem acessar o espaço a qualquer momento, mediante agendamento prévio com a HostDime (realizado através de abertura de chamado no painel do cliente <a href='https://core.hostdime.com.br/' target='_blank'>Core</a>) e de acordo com as suas exigências para o apropriado comportamento. Você é responsável legal pelos atos de seus contatos autorizados, assim como pelos dados pessoais deles(nome, telefone, email, data de nascimento, RG/CPF), fornecidos por você em seu painel de cliente Core, caracterizando você como Controlador dos Dados Pessoais desses contatos autorizados. Os dados pessoais fornecidos, tem por finalidade acesso ao painel de contato autorizado, para agendar visitas, acessar seus serviços de colocation no Data Center, bem como outras funções administrativas no painel, conforme definido na <Link to='politica-de-privacidade'>Política de Privacidade</Link>.</Paragraph>
                  <Paragraph><span>1.5</span> Os Equipamentos fornecidos pelo cliente devem ser instalados, operados, inspecionados, reparados, substituídos e removidos (i) apenas por pessoas autorizadas pelo cliente devidamente cadastrado e se aplicável de acordo com as práticas padrões da indústria, licenciado; e (ii) de uma maneira segura e cuidadosa. Um aviso prévio por escrito à HostDime é requerido para realizar qualquer carregamento/descarregamento de equipamento. Você não deve permitir quaisquer ônus contra a infraestrutura ou qualquer equipamento da HostDime.</Paragraph>
                  <Paragraph><span>1.6</span> Você não deve alterar as instalações ou espaço (incluindo cabos e fontes de alimentação) sem o consentimento prévio da HostDime por escrito para análise e aprovação de seus planos e especificações para quaisquer alterações. Todos os itens adicionais aos seus equipamentos, como os já citados, fazem parte das instalações e você não deve alterar a menos que seja autorizado pela HostDime. Qualquer outra mudança deve ser sob sua responsabilidade/custo. No prazo de quinze dias após a rescisão ou término dos serviços de colocation, você deve recolher todos os seus equipamentos de nossas instalações e deverá, a pedido da HostDime, organizar (despesas sob sua inteira responsabilidade) o espaço para sua reutilização até a data limite. Se você não recolher ou organizar o espaço para reutilização, a HostDime poderá fazê-lo e está autorizada a passar os custos para você. Se qualquer equipamento fornecido pelo cliente permanecer em nossas instalações após o período de 15 (quinze) dias, a critério exclusivo da HostDime, os equipamentos poderão ser removidos das instalações, armazenados e usados para valores devidos à HostDime, se for o caso. No exercício de qualquer um dos direitos acima mencionados, a HostDime não exonera o cliente das suas obrigações de pagamento nos termos do contrato. A HostDime poderá rescindir o presente acordo se após a inspeção de nossos técnicos for determinado que o seu equipamento não está de acordo com estes termos, e se alguma falha for identificada em seu equipamento e você não corrigir a mesma dentro de dez dias contados a partir do nosso aviso prévio.</Paragraph>
                  <H6>2. Direito de Desligar e Remover</H6>
                  <Paragraph>Se você deixar de pagar qualquer valor devido e tal falha continuar por mais de dez dias, incluído no nosso direito de proibir o seu uso do espaço, estará no nosso direito desligar e remover alguns ou todos os equipamentos do espaço (incluindo quaisquer dados e/ou softwares), armazenar tais equipamentos por um período que não exceda três meses e de avaliar taxas razoáveis para este período de armazenamento. Após a conclusão do período de armazenagem permitido podemos, às suas custas, descartar o equipamento armazenado de maneira determinada pela HostDime com qualquer produto aplicado a quaisquer valores devidos a nós. No exercício de qualquer dos direitos acima mencionados, a HostDime não exonera o cliente das suas obrigações de pagamento nos termos do contrato.</Paragraph>
                  <H6>3. Segurança Física</H6>
                  <Paragraph>A menos que de outro modo estabelecido no pedido, as medidas de proteção física que prestamos são constituídas exclusivamente a certas travas físicas, verificações de segurança e monitoramento das instalações. O uso dos serviços por parte do cliente constitui o reconhecimento da suficiência de tais medidas de proteção. <span>O CLIENTE RECONHECE E ACEITA QUE AS SOLUÇÕES DE SEGURANÇA PREVISTAS COMO PARTE DOS SERVIÇOS não garantem SEGURANÇA DE REDE</span> ou previnem incidentes de segurança, não se destinam a ser uma solução de <span>SEGURANÇA ABRANGENTE</span> e são considerados serviços para os quais a HostDime limita a sua responsabilidade e se isenta de garantias fornecidas NESTE TSI.</Paragraph>
                  <H6>4. Serviços de Interconexão de Redes</H6>
                  <Paragraph>Você pode usar o espaço para interconectar com serviços de telecomunicações fornecido por terceiros sob as seguintes circunstâncias: (i) conexões cruzadas apenas são permitidas para placas de rede adicionais; e (ii) os serviços devem estar dentro de uma proximidade física razoável, de modo que o cabeamento padrão possa conectá-los. A HostDime poderá manter outros tipos de conexão cruzada mediante o pagamento de taxas adicionais.</Paragraph>
                  <H6>5. Realocação</H6>
                  <Paragraph>Reservamo-nos o direito de exigir a realocação de seu equipamento. Embora este processo não seja feito de forma arbitrária, em algum momento poderá ser necessário para realizar melhorias em nossas instalações de acordo com nossos planejamentos. Se isto for necessário, você será avisado com antecedência de 7 (sete) dias.</Paragraph>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <H6>1. As seguintes disposições aplicam-se ao serviço de Servidor Dedicado</H6>
                  <Paragraph><span>1.1</span> Você tem o direito de utilizar os serviços abrangidos por este parágrafo 1.2 para conectar a nossa rede basicamente 24/7/365 (365 dias por ano), limitado por este TSI. Você não terá acesso físico ao equipamento utilizado para fornecer os serviços, de forma que o equipamento é alugado e não pode ser vendido a você. Você não terá nenhum direito sobre este equipamento.</Paragraph>
                  <Paragraph><span>1.2</span> Os serviços cobertos por este parágrafo 1.2 são fornecidos para você com uma base padrão. Eles não são personalizados para seu uso. Em alguns casos, eles podem ter configurações padrão do fabricante. Isto significa que para seu site, sistema ou aplicação funcionar de forma adequada, configurações adicionais devem ser realizados por você. Você assume a responsabilidade final para garantir que os serviços estão configurados para atender às suas necessidades de forma privada e segura. Seu site ou quaisquer outras ferramentas que você considerar necessário a utilização deverão ser compatíveis com os serviços. A menos que estabelecido durante o processo de contratação, não somos obrigados a modificar os serviços para adequar à sua utilização.</Paragraph>
                  <Paragraph><span>1.3</span> Servidores Dedicados tem uma largura de banda aplicável tal como estabelecido durante o pedido. Se em qualquer mês você ultrapassar o limite definido você será cobrado pelo uso adicional de banda.</Paragraph>
                  <Paragraph>Largura de banda não utilizada não poderá ser acumulada/transferida para o próximo mês.</Paragraph>
                  <H6>2. Serviços de Interconexão de Redes</H6>
                  <Paragraph>Você pode usar o espaço para interconectar com serviços de telecomunicações fornecido por terceiros sob as seguintes circunstâncias: (i) conexões cruzadas apenas são permitidas para placas de rede adicionais e (ii) os serviços devem estar dentro de uma proximidade física razoável, de modo que o cabeamento padrão possa conectá-los. A HostDime poderá manter outros tipos de conexão cruzada mediante o pagamento de taxas adicionais.</Paragraph>
                  <H6>3. Realocação</H6>
                  <Paragraph>Reservamo-nos o direito de exigir a realocação de seu equipamento. Embora este processo não seja feito de forma arbitrária, em algum momento poderá ser necessário para realizar melhorias em nossas instalações de acordo com nossos planejamentos. Se Isto for necessário, você será avisado com antecedência de 7 (sete) dias.</Paragraph>
                  <H6>4. Transferências de Dados</H6>
                  <Paragraph>A equipe da HostDime Brasil está apta à realização de transferências e migrações quando solicitado e acordado com o cliente. Porém, toda solicitação está sujeita à análise de viabilidade técnica e de cobrança de taxa para execução.</Paragraph>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <H6>1. As seguintes disposições aplicam-se ao serviço de Cloud Server</H6>
                  <Paragraph><span>1.1</span> Você tem o direito de utilizar os serviços abrangidos por este parágrafo 1.2 para conectar a nossa rede basicamente 24/7/365 (365 dias por ano), limitado por este TSI. Você não terá acesso físico ao equipamento utilizado para fornecer os serviços.</Paragraph>
                  <Paragraph><span>1.2</span> Os serviços cobertos por este parágrafo 1.2 são fornecidos para você com uma base padrão. Eles não são personalizados para seu uso. Em alguns casos, eles podem ter configurações padrão do fabricante. Isto significa que para seu site, sistema ou aplicação funcionar de forma adequada, configurações adicionais devem ser realizados por você. Você assume a responsabilidade final para garantir que os serviços estão configurados para atender às suas necessidades de forma privada e segura. Seu site ou quaisquer outras ferramentas que você considerar necessário a utilização deverão ser compatíveis com os serviços. A menos que estabelecido durante o processo de contratação, não somos obrigados a modificar os serviços para adequar à sua utilização.</Paragraph>
                  <Paragraph><span>1.3</span> A HostDime Brasil desenvolve uma Política de Segurança e Privacidade voltada exclusivamente ao ambiente de Cloud Server localizado no DC Nordeste - Brasil, através do modelo de implantação de nuvem publica, utilizando o modelo de infraestrutura como serviço.</Paragraph>
                  <H6>2. Pagamentos</H6>
                  <Paragraph>O serviço de Cloud da HostDime utiliza um sistema de dedução de crédito. Dessa forma, você precisa adicionar créditos ao sistema para fazer utilização do serviço. Você pode adicionar a partir de R$ 75,00 (valor mínimo). Uma vez que a cobrança é efetuada por hora, o sistema registrará sua utilização por hora e deduzirá esse valor de seus créditos. São aceitos os métodos de pagamento definidos nos termos de serviço: cartão de crédito, PayPal, boleto bancário e Bitcoin.</Paragraph>
                  <H6>3. Papéis e Responsabilidades</H6>
                  <Paragraph>A HostDime Brasil define um modelo de responsabilidade compartilhada entre os papéis de  provedor de serviços e cliente apresentando as atividades definidas para cada papel estabelecido, conforme descrito abaixo.</Paragraph>
                  <Paragraph>Responsabilidades da HostDime Brasil como Provedora de Serviços de Nuvem:</Paragraph>
                  <ul>
                    <li>&#9679; Proteger e manter a infraestrutura que suporta todas as funcionalidades do serviço de Cloud Computing. Esta infraestrutura abrange: hardware, software, redes e instalações físicas;</li>
                    <li>&#9679; Prover um canal de comunicação para solução de problemas e solicitações, além de contatos relacionados à privacidade através do painel de cliente <a href='https://core.hostdime.com.br/' target='_blank'>Core</a>.</li>
                  </ul>
                  <Paragraph>Responsabilidades do Cliente do Serviço de Nuvem:</Paragraph>
                  <ul>
                    <li>&#9679; Configuração e gerenciamento da segurança das instâncias virtuais a nível de sistema operacional convidado, incluindo atualizações e patches de segurança, qualquer utilitário de software instalado pelo cliente nas instâncias, bem como a configuração do firewall disponibilizado pela HostDime para o controle de tráfego de cada instância e todos os dados inseridos nas instâncias virtuais.</li>
                  </ul>
                  <H6>4. Requisitos Legais</H6>
                  <Paragraph>Atualmente não há legislação específica relacionada a provisão de serviços de Cloud Computing. No entanto, a HostDime Brasil estabelece um padrão de conformidade com a Segurança da Informação e Privacidade baseadas nas normas abaixo:</Paragraph>
                  <ul>
                    <li>&#9679; ISO 27001:2013</li>
                    <li>&#9679; ISO 27701:2019</li>
                    <li>&#9679; ISO 27017:2016</li>
                    <li>&#9679; ISO 27018:2019</li>
                  </ul>
                  <Paragraph>Além de estar aderente às leis nacionais e internacionais sobre segurança da informação e privacidade:</Paragraph>
                  <ul>
                    <li>&#9679; Marco Civil da Internet</li>
                    <li>&#9679; LGPD</li>
                    <li>&#9679; GDPR</li>
                  </ul>
                  <H6>5. Privacidade no Ambiente CloudServer</H6>
                  <Paragraph><span>5.1</span> Uso de dados para marketing</Paragraph>
                  <Paragraph>Dados de nossos clientes não são usados para fins de marketing sem seu consentimento explícito. Com o Cloud Server HostDime, em conformidade com a ISO/IEC 27018, garantimos aos nossos clientes que seus dados não serão usados para fins de marketing sem que o cliente tenha dado seu consentimento. Tal consentimento não poderá ser condição para contratação e uso do serviço Cloud Server HostDime.</Paragraph>
                  <Paragraph><span>5.2</span> Retorno, transferência e descarte de Dados Pessoais</Paragraph>
                  <Paragraph>Disponibilizamos aos clientes de Cloud Server HostDime a possibilidade de retornar seus dados a qualquer momento, assim como transferi-los para um outro operador ou controlador. Quanto ao descarte dos dados, este é realizado mediante solicitação de cancelamento do serviço ou quando suspenso por mais de 30 dias. Caso a HostDime Brasil venha a utilizar subcontratados para processar Dados Pessoais de clientes, estes subcontratados serão divulgados nestes termos de serviço ou em nossa política de privacidade.</Paragraph>
                  <Paragraph><span>5.3</span> Divulgação de Dados Pessoais</Paragraph>
                  <Paragraph>Os dados pessoais de clientes Cloud Server HostDime apenas serão divulgados mediante cumprimento de obrigação legal, como em investigações criminais, por exemplo. Tal divulgação será comunicada ao cliente, a menos que para manter o sigilo da investigação seja proibido por lei.</Paragraph>
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <H6>1. As seguintes disposições aplicam-se ao serviço de Virtual Private Server (VPS)</H6>
                  <Paragraph><span>1.1</span> Você tem o direito de utilizar os serviços abrangidos por este parágrafo 1.2 para conectar a nossa rede basicamente 24/7/365 (365 dias por ano), limitado por este TSI. Você não terá acesso físico ao equipamento utilizado para fornecer os serviços.</Paragraph>
                  <Paragraph><span>1.2</span> Os serviços cobertos por este parágrafo 1.2 são fornecidos para você com uma base padrão. Eles não são personalizados para seu uso. Em alguns casos, eles podem ter configurações padrão do fabricante. Isto significa que para seu site, sistema ou aplicação funcionar de forma adequada, configurações adicionais devem ser realizados por você. Você assume a responsabilidade final para garantir que os serviços estão configurados para atender às suas necessidades de forma privada e segura. Seu site, ou quaisquer outras ferramentas que você considerar necessária a utilização deverão ser compatíveis com os serviços. A menos que estabelecido durante o processo de contratação, não somos obrigados a modificar os serviços para adequar à sua utilização.</Paragraph>
                  <Paragraph><span>1.3</span> Você garantirá que nem você nem qualquer um dos seus usuários finais façam uso excessivo ou desnecessário de nossa rede, concordando com os itens descritos a seguir.</Paragraph>
                  <Paragraph><span>1.4</span> Uma política de "uso racional" poderá ser aplicada referente ao tráfego de dados sobre os serviços de VPS. Isto significa que se seu uso dos serviços de VPS gerar mais tráfego do que o habitual para os clientes em situação semelhante, poderemos solicitar que você adeque o seu uso a fim de evitar exceder o padrão estabelecido. Se você não realizar os ajustes necessários, nos reservamos o direito de terminar o seu serviço VPS e avaliar uma taxa de rescisão antecipada.</Paragraph>
                  <Paragraph><span>1.5</span> Os Servidores Virtuais Privados (VPS) tem uma largura de banda aplicável tal como estabelecido durante o pedido. Se em qualquer mês você ultrapassar o limite definido, você será cobrado pelo uso adicional de banda. Largura de banda não utilizada não poderá ser acumulada/transferida para o próximo mês.</Paragraph>
                  <Paragraph><span>1.6</span> Nossos Serviços VPS permitem conectar com a nossa rede utilizando um ambiente isolado que, apesar de você ter controle virtual sobre todas as características e aspectos de um servidor dedicado, alguns recursos são compartilhados. Você se compromete em não fazer uso dos recursos do serviço que prejudique os demais clientes do servidor. Você não vai alterar ou tentar alterar, mecanismos, incluindo software, implementados por nós para facilitar o compartilhamento do servidor. Você entende que certos aspectos dos serviços de VPS foram projetados para facilitar a utilização e administração do equipamento. Você não pode encerrar o contrato com base em nossa forma de administrar estes recursos.</Paragraph>
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <H6>1. As seguintes disposições aplicam-se ao serviço de Revenda e Hospedagem</H6>
                  <Paragraph><span>1.1</span> Você tem o direito de utilizar os serviços abrangidos por este parágrafo para conectar a nossa rede, basicamente 24/7/365 (365 dias por ano), limitado por este TSI. Você não terá acesso físico ao equipamento utilizado para fornecer os serviços.</Paragraph>
                  <Paragraph><span>1.2</span> Os serviços cobertos por este parágrafo são fornecidos para você com uma base padrão. Eles não são personalizados para seu uso. Em alguns casos, eles podem ter configurações padrão do fabricante. Isto significa que para seu site, sistema ou aplicação funcionar de forma adequada, configurações adicionais devem ser realizados por você. Você assume a responsabilidade final para garantir que os serviços estão configurados para atender às suas necessidades de forma privada e segura. Seu site, ou quaisquer outras ferramentas que você considerar necessária a utilização deverão ser compatíveis com os serviços. A menos que estabelecido durante o processo de contratação, não somos obrigados a modificar os serviços para adequar à sua utilização.</Paragraph>
                  <Paragraph><span>1.3</span> Você garantirá que nem você nem qualquer um dos seus usuários finais façam uso excessivo ou desnecessário de nossa rede, concordando com o seguinte:</Paragraph>
                  <Paragraph>Uma política de "uso racional" poderá ser aplicada para manter o tráfego de dados estável em serviços compartilhados. Isto significa que se o seu uso nos serviços compartilhados gerar mais tráfego do que o habitual para clientes em situação semelhante, poderemos solicitar que você adeque o seu uso a fim de evitar exceder o padrão estabelecido. Se você não fizer os ajustes necessários, nos reservamos o direito de encerrarmos a parceria e avaliaremos uma taxa de rescisão antecipada. Serviços compartilhados não podem ser utilizados para mensagens instantâneas, salas de bate papo ou atividades similares, nem podem ser utilizadas para a distribuição de softwares.</Paragraph>
                  <H6>2. Transferências de Dados </H6>
                  <Paragraph>A equipe da HostDime Brasil está apta à realização de transferências e migrações quando solicitado e acordado com o cliente. Porém, toda solicitação está sujeita à análise de viabilidade técnica e de cobrança de taxa para execução.</Paragraph>
                </TabPanel>
                <TabPanel value={value} index={6}>
                  <H6>1. As seguintes disposições aplicam-se ao serviço de Registro de Domínio</H6>
                  <Paragraph><span>1.1</span> Revendemos serviço de registro de nome através do e.nom e TuCows.</Paragraph>
                  <Paragraph>Se seu domínio é registrado pelo Enom, o seguinte acordo aplica-se à você: <a href='http://www.enom.com/terms/agreement.aspx'>http://www.enom.com/terms/agreement.aspx</a></Paragraph>
                  <Paragraph>Se seu domínio é registrado pelo TuCows, o seguinte acordo aplica-se à você: <a href='http://www.opensrs.com/docs/contracts/exhibita.htm'>http://www.opensrs.com/docs/contracts/exhibita.htm</a>; e <a href='http://www.icann.org/en/registrars/registrant-rights-responsibilities-en.htm'>http://www.icann.org/en/registrars/registrant-rights-responsibilities-en.htm</a></Paragraph>
                  <Paragraph>Adicionalmente, as políticas do ICANN aplicam-se à você: <a href='http://www.icann.org/en/resources/registrars/consensus-policies'>http://www.icann.org/en/resources/registrars/consensus-policies</a></Paragraph>
                  <Paragraph>Se seu domínio é registrado pelo Registro.br, o seguinte acordo aplica-se à você: <a href='https://registro.br/dominio/contrato/'>https://registro.br/dominio/contrato/</a></Paragraph>
                  <Paragraph>Você concorda e entende que os acordos relacionados acima podem conter ainda documentos adicionais. É sua obrigação ler, compreender e concordar em se comprometer com os acordos que estão incorporados neste TSI.</Paragraph>
                  <Paragraph><span>1.2</span> Para que seu domínio aponte para o servidor da HostDime você precisa mudar seu DNS no órgão de registro no qual contratou o seu domínio. Estes DNS serão providos no e-mail de boas vindas e são definidos em par (ns*.<a href='dizinc.com'>dizinc.com</a> ou dns*.<a href='dizinc.com'>dizinc.com</a>). Cada servidor possui endereços de DNS único, então se você cancelar uma conta e meses após retornar a HostDime, os endereços provavelmente serão distintos.</Paragraph>
                  <Paragraph><span>1.3</span> Por favor, note que o procedimento acima não é uma transferência de domínio. Uma transferência de domínio é se você quer fisicamente transferir seu domínio para o registrar da HostDime e sair do seu órgão de registro original. No caso de registros de domínio internacional, ao realizar a transferência o domínio será renovado por mais um ano a partir da próximo data de expiração.</Paragraph>
                  <Paragraph><span>1.4</span> A HostDime tomará as medidas necessárias para registrar nomes de domínio sob os cuidados do cliente quando requisitada. De qualquer modo, os clientes são responsáveis por renovar os seus nomes de domínio. A HostDime não se responsabiliza por falhas na renovação dos nomes de domínio. Se seu nome de domínio foi registrado via nossa empresa, você deve verificar e tomar as ações necessárias para renovar um domínio que esteja se expirando. Nós não cuidamos de renovações a menos que solicitado especificamente ao enviar um chamado para nosso suporte financeiro. Se você tem quaisquer questões sobre mudanças de DNS ou nomes de domínio em geral, por favor, entre em contato conosco através de sua Área do Cliente.</Paragraph>
                </TabPanel>
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
  values: PropTypes.object
}

const StaticPage = (props) => {
  return (
    <Layout>
      <Seo title='Termos do Serviço' />
      <PageTemplate
        title='Termos do Serviço'
        {...props}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "cloud-server/cloud-server.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default (StaticPage)
